import { FunctionComponent } from "react";
import styled from "styled-components";
import Spinner from "./styledComponents/Spinner";
import StyledButton from "./styledComponents/StyledButton";

type Props = {
    isLoading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button:FunctionComponent<Props> = ({children, isLoading, ...rest}) => {
    return (
        <ButtonBlock {...rest}>
            {isLoading
                ? <Spinner />
                : children
            }
        </ButtonBlock>
    )
}

const ButtonBlock = styled(StyledButton)`

`;
export default Button;