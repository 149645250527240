import { useEffect, useState } from "react";
import { useExpireTimeState } from "../atoms/authState";
import getNow from "../lib/utils/getNow";
import timeFormat from "../lib/utils/timeFormat";

export default function useExpireTimeCounter() {
    const [expireTime] = useExpireTimeState();
    const [isExpired, setIsExpired] = useState(false);
    const [remainTime, setRemainTime] = useState("02 : 59");

    useEffect(() => {
        const remainMillisecond = expireTime ? expireTime - getNow() : 0;
    
        let time = remainMillisecond ?? 0;
        if (time < 0) {
            setIsExpired(true);
            return;
        }
        const count = setInterval(() => {
            time -= 1000;
            setRemainTime(timeFormat(time));
            if (time < 0) {
                clearInterval(count);
                setIsExpired(true);
            }
        }, 1000);
        return () => {
            clearInterval(count)
        }
    }, [expireTime])

    return {
        remainTime,
        isExpired
    }
}