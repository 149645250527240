import styled from "styled-components";

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border-top-color: black;
    margin: auto;
    width: 20px;
    height: 20px;
    transition: opacity 250ms;
    animation: rotate-spinner 0.7s linear;
    animation-iteration-count: infinite;
    @keyframes rotate-spinner {
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Spinner;