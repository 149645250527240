import styled from "styled-components";

const FormErrorMessageBox = styled.p`
    text-align: center;
    background: #ffd7d7;
    padding: 10px 10px;
    border-radius: 3px;
    margin-top: 10px;
    color: red;
`;

export default FormErrorMessageBox;