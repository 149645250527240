import { FunctionComponent } from "react";
import styled from "styled-components";

type Props = {
    title: string;
}

const MainHeader:FunctionComponent<Props> = ({title}) => {
    return (
        <MainHeaderBlock>
            <h1>{title}</h1>
        </MainHeaderBlock>
    )
}

const MainHeaderBlock = styled.header`
    margin-bottom: 40px;
    >h1 {
        font-size: 30px;
    }
`;
export default MainHeader;