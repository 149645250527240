import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useConfirmTokenState } from '../../../atoms/authState';
import Button from '../../../components/Button';
import ArticleHeading from '../../../components/styledComponents/ArticleHeading';
import FormErrorMessageBox from '../../../components/styledComponents/FormErrorMessageBox';
import StyledInput from '../../../components/styledComponents/StyledInput';
import useFetchReducer from '../../../hooks/useFetchReducer';
import changePassword from '../../../lib/api/resetPassword/changePassword';

type Props = {
    
}

const ChangePassword: React.FunctionComponent<Props> = ({ }) => {
    const [password, setPassword] = useState("");
    const [confirmToken] = useConfirmTokenState();
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [fetchState, dispatch] = useFetchReducer();
    const history = useHistory();

    const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setPassword(value);
    }
    const handleChangeConfirmedPassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setConfirmedPassword(value);
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        dispatch({ type: 'FETCH_INIT' });
        try {
            await changePassword(confirmToken, password, confirmedPassword);
            dispatch({ type: 'FETCH_SUCCESS' });
            history.replace('/reset-password/complate');
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    return (
        <ChangePasswordBlock>
            <ArticleHeading>
                비밀번호 변경
            </ArticleHeading>
            <Form onSubmit={handleSubmit}>
                <StyledInput type="password" placeholder="New Password" value={password} onChange={handleChangePassword} />
                <StyledInput type="password" placeholder="New Comfirm Password" value={confirmedPassword} onChange={handleChangeConfirmedPassword} />
                <Button type="submit" isLoading={fetchState.isLoading} disabled={fetchState.isLoading}>비밀번호 변경</Button>
            </Form>
            {fetchState.error && <FormErrorMessageBox>{fetchState.error}</FormErrorMessageBox>}
        </ChangePasswordBlock>
    )
}

const ChangePasswordBlock = styled.article`

`;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    >* {
        margin-bottom: 20px;
        width: 100%;
    }
    >button {
        width: 150px;
    }
`;

export default ChangePassword;