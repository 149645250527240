import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useExpireTimeState, useIssueTokenState } from '../../../atoms/authState';
// import { useUserInfoState } from '../../../atoms/userState';
import Button from '../../../components/Button';
import ArticleHeading from '../../../components/styledComponents/ArticleHeading';
import FormErrorMessageBox from '../../../components/styledComponents/FormErrorMessageBox';
import StyledInput from '../../../components/styledComponents/StyledInput';
import useFetchReducer from '../../../hooks/useFetchReducer';
import requestToken from '../../../lib/api/resetPassword/requestToken';
import getNow from '../../../lib/utils/getNow';

type Props = {

}

const ValidateToken: React.FunctionComponent<Props> = ({ }) => {
    const [, setIssueToken] = useIssueTokenState();
    const [, setExpireTime] = useExpireTimeState();
    // const [user] = useUserInfoState();
    const [email, setEmail] = useState(""); //NOTE: 로그인 시 자동으로 userEmail을 기본값으로 넣는 경우(필요할 시 대체하기) --> useState(user?.email ?? "")
    const history = useHistory();
    const [fetchState, dispatch] = useFetchReducer();

    const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setEmail(value);
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        dispatch({ type: 'FETCH_INIT' });
        try {
            const data = await requestToken(email);
            dispatch({ type: 'FETCH_SUCCESS' });
            setIssueToken(data.issueToken);
            setExpireTime(getNow() + data.remainMillisecond);
            history.replace('/reset-password/validate-token')
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    return (
        <ValidateTokenBlock>
            <ArticleHeading>
                인증 코드 발급 요청
            </ArticleHeading>
            <Form onSubmit={handleSubmit}>
                <StyledInput type="email" value={email} onChange={handleChangeEmail} placeholder="Email"/>
                <Button type="submit" isLoading={fetchState.isLoading} disabled={fetchState.isLoading}>이메일 검증</Button>
            </Form>
            {fetchState.error && <FormErrorMessageBox>{fetchState.error}</FormErrorMessageBox>}
        </ValidateTokenBlock>
    )
}

const ValidateTokenBlock = styled.article`

`;

const Form = styled.form`
    display: flex;
    >input {
        width: 100%;
    }
    >button {
        flex: 1 0 30%;
    }
`;

export default ValidateToken;