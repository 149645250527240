import axios from "axios";

export default async function changePassword(confirmToken: string|null, newPassword: string, newPasswordConfirm: string) {
    const response = await axios.patch(
        `https://ably-frontend-interview-server.vercel.app/api/reset-password`,
        {
            email: "ably368@dummy.com",
            confirmToken,
            newPassword,
            newPasswordConfirm, 
        },
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    )
    console.log(`change password response`, response);
    return response.data;
}