import { useReducer } from "react";
import getAPIErrMsg from "../lib/utils/getAPIErrMsg";

type StateType = {
    isLoading: boolean;
    error: string | null;
}
export default function useFetchReducer(initialState?: StateType) {
    const reducer = (state:StateType, action:any) => {
        switch (action.type) {
            case 'FETCH_INIT':
                return {
                    ...state,
                    isLoading: true,
                    error: null
                };
            case 'FETCH_SUCCESS':
                return {
                    ...state,
                    isLoading: false,
                    error: null
                };
            case 'FETCH_FAILURE':
                let error = null;
                const errMsg = getAPIErrMsg(action.payload.error);
                if (errMsg) {
                    error = errMsg;
                } else {
                    error = "An Error occured";
                    console.error(error);
                }
                return {
                    ...state,
                    isLoading: false,
                    error,
                };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState ?? {error: null, isLoading: false});

    return [state, dispatch] as [typeof state, typeof dispatch];
}