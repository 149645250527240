import styled from "styled-components";

const StyledButton = styled.button`
    border: 1px solid #efefef;
    background: none;
    height: 40px;
    cursor: pointer;
    &:hover:enabled {
        border-color: gray;
    }

    &:disabled {
        cursor: default;
    }
`;

export default StyledButton;