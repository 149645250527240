import axios from "axios";

export default async function requestToken(email: string) {
    const response = await axios.get<{
        issueToken: string;
        remainMillisecond: number;
    }>(
        `https://ably-frontend-interview-server.vercel.app/api/reset-password?email=${email}`,
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    )

    console.log(`requestToken response: `, response);
    return response.data;
}