import { atom, useRecoilState } from 'recoil';

export const issueTokenState = atom<string | null>({
    key: 'issueTokenState',
    default: null
})

export const expireTimeState = atom<number | null>({
    key: 'expireTimeState',
    default: null
})

export const confirmTokenState = atom<string | null>({
    key: 'confirmTokenState',
    default: null
})

export function useIssueTokenState() {
    return useRecoilState(issueTokenState);
}

export function useExpireTimeState() {
    return useRecoilState(expireTimeState)
}

export function useConfirmTokenState() {
    return useRecoilState(confirmTokenState);
}