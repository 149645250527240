import React from 'react';
import styled from 'styled-components';

type Props = {
}

const Complate:React.FunctionComponent<Props> = ({}) => {

    return (
        <ComplateBlock>
            <h3>비밀번호 변경 성공</h3>
            <p>로그인 할때 변경된 비밀번호로 로그인 해주세요.</p>
        </ComplateBlock>
    )
}

const ComplateBlock = styled.article`
    width: 100%;
    height: 300px;
    border: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    >p {
        color: gray;
    }

`;
export default Complate;